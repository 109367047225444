import React, { createContext, useReducer } from 'react';

const initialState = {
  documentCategories: {},
};
const dashboardStore = createContext(initialState);
const { Provider } = dashboardStore;

const reducer = (state, action) => {
  if (action.type === 'LOG_OUT') {
    return {
      ...initialState,
    };
  } else {
    return {
      ...state,
      [action.type]: action.newVal,
    };
  }

  // switch (action.type) {
  //   case action.type:
  //     return {
  //       ...state,
  //       [action.type]: action.newVal,
  //     };
  //   default:
  //     throw new Error();
  // }
};

export function useCallReducer() {
  const [state, dispatch] = useReducer(reducer, initialState);
  return { state, dispatch };
}

const DashboardProvider = ({ children }) => {
  return <Provider value={useCallReducer()}>{children}</Provider>;
};

export { dashboardStore, DashboardProvider };
