import React, { Component } from 'react';

import DashboardContext from './Context';
import UserContext from '../app/Context';
import customerApi from '../../api/customer';

class Provider extends Component {
  static contextType = UserContext;

  state = {
    /**
     * Reference to the getSession method from UserContext
     */
    getSession: this.context.getSession,

    /**
     * The auth object from the UserContext
     */
    auth: this.context.auth,

    /**
     * Returns dashboard data for a user.
     * @param {String} token- authentication token
     * @returns {Promise}
     */
    getDashboard: async () => {
      try {
        const res = await customerApi.getDashboard(this.context.auth.jwtToken);

        if (res.status <= 202) return res;
        else if (res.status >= 400) throw new Error(res.statusText);
      } catch (e) {
        console.log('getDashboard error: ', e);
        return;
      }
    },

    /**
     * Returns a list of documents for a user.
     * @param {String} token- authentication token
     * @returns {Promise}
     */
    getCategories: async (bId) => {
      try {
        const res = await customerApi.getDocumentCategories(bId, this.context.auth.jwtToken);

        if (res.status <= 202) return res;
        else if (res.status >= 400) throw new Error(res.statusText);
      } catch (e) {
        console.log('getDocumentCategories error: ', e);
        return;
      }
    },

    /**
     * Returns a match result for an app.
     * @param {String} token- authentication token
     * @returns {Promise}
     */
    getMatch: async (bId) => {
      try {
        const res = await customerApi.getMatch(bId, this.context.auth.jwtToken);

        if (res.status <= 202) return res.data;
        else if (res.status >= 400) throw new Error(res.statusText);
      } catch (e) {
        console.log('getMatch error: ', e);
        return;
      }
    },

    /**
     * Sets the match preference selected by the user.
     * @param {String} appId
     * @param {String} matchId
     * @param {Object} data
     * @returns {Object} - the match preference data
     */
    setMatchPreference: async (appId, matchId, data) => {
      try {
        const res = await customerApi.setMatchPreference(appId, matchId, data, this.context.auth.jwtToken);

        if (res.status <= 202) return res;
        else if (res.status >= 400) throw new Error(res.statusText);
      } catch (e) {
        console.log('setMatchPreference error: ', e);
        return;
      }
    },

    /**
     * Returns a list of AWS S3 Signed Upload URLs for document uploads.
     * @param {String} appId
     * @param {Object} data
     * @returns {Object} - response object
     */
    getSignedUrls: async (appId, data) => {
      try {
        const res = await customerApi.getSignedUrls(appId, data, this.context.auth.jwtToken);

        if (res.status <= 202) return res.data;
        else if (res.status >= 400) throw new Error(res.statusText);
      } catch (e) {
        console.log('getSignedUrls error: ', e);
        return;
      }
    },

    /**
     * Returns the referral data for a user.
     * @param {String} token- authentication token
     * @returns {Object} - referral data
     */
    getReferrals: async () => {
      try {
        const res = await customerApi.getReferrals(this.context.auth.jwtToken);

        if (res.status <= 202) return res.data;
        else if (res.status >= 400) throw new Error(res.statusText);
      } catch (e) {
        console.log('getReferral error: ', e);
        return;
      }
    },

    /**
     * Returns the offer data for a user.
     * @param {String} appId
     */
    getOffers: async (appId) => {
      try {
        const res = await customerApi.getOffers(appId, this.context.auth.jwtToken);

        if (res.status <= 202) return res.data;
        else if (res.status >= 400) throw new Error(res.statusText);
      } catch (e) {
        console.log('getOffers error: ', e);
        return;
      }
    },

    /**
     * Actions the offer data for a user.
     * @param {String} method - offer based method
     * @param {String} uri - offer based uri
     * @param {String} id - application id
     * @param {Object} data - offer data
     */
    offerAction: async (method, uri, id, data) => {
      try {
        const res = await customerApi.offerAction(method, uri, id, data, this.context.auth.jwtToken);

        if (res.status <= 202) return res.data;
        else if (res.status >= 400) throw new Error(res.statusText);
      } catch (e) {
        console.log('offerAction error: ', e);
        return;
      }
    },
  };

  render() {
    const { children } = this.props;
    const { getDashboard, getCategories, getMatch, getSignedUrls, setMatchPreference, getReferrals, getOffers, offerAction } = this.state;

    return (
      <DashboardContext.Provider
        value={{
          getDashboard,
          getCategories,
          getMatch,
          getSignedUrls,
          setMatchPreference,
          getReferrals,
          getOffers,
          offerAction,
        }}
      >
        {children}
      </DashboardContext.Provider>
    );
  }
}

export { Provider as DashboardProvider };
