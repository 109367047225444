import { useState, useEffect } from 'react';

/** 
 * Setting the custom bg via URL `bg` query string parameter 
 * @param {boolean} isEmbed - is the app embedded in an iframe 
 * @returns {string} - custom background color
 */
export default function useCustomBg(isEmbed) {
  const [customBg, setCustomBg] = useState('fff');
  
  useEffect(() => {
    const url = new URL(window.location.href),
      bg = url?.searchParams?.get('bg');
    if (bg && isEmbed) {
      let isValidHex = new RegExp(/^([0-9A-F]{3}){1,2}$/i);

      // This used to be a enum regex, but we're allowing any hex color now
      // let isAllowed = new RegExp(
      //   ['FFF', 'FFFFFF', 'F5F7FA', 'CCEFC4', 'B0E5F8', 'FDD4D0'].join('|'),
      //   'i'
      // );

      if (isValidHex.test(bg)) {
        document.body.style.backgroundColor = bg;
        setCustomBg('#' + bg);
      }
    }
  }, []); //eslint-disable-line

  return customBg;
};