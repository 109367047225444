import axios from "axios";
import { has } from "lodash";

// Create a new instance of axios
const axiosInstance = axios.create();

// Request interceptor
axiosInstance.interceptors.request.use(
  async (c) => {
    const config = { ...c, headers: { ...c.headers } };
    config.headers['content-type'] = 'application/json';
    if (has(config, 'token')) config.headers['authorization'] = `Bearer ${config.token}`;
    config.data = JSON.stringify(config.data);
    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);

export default axiosInstance;