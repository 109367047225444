import React from "react";

import Nav from "../Nav";
import Footer from "../Footer";
import SEO from "../Seo";

class MainLayout extends React.Component {
  render() {
    const { embed, showFlag, children, title, description, pathname } =
      this.props;
    return (
      <div className="mainLayout">
        <SEO title={title} description={description} pathname={pathname} />
        {embed ? (
          children
        ) : (
          <div
            style={{
              display: `flex`,
              flexDirection: `column`,
              minHeight: `100vh`,
            }}
          >
            <Nav showFlag={showFlag} />
            {children}
            <Footer />
          </div>
        )}
      </div>
    );
  }
}

export default MainLayout;
