export const customer = [
  {
    path: '/c/:alias1',
    isEmbed: false,
    options: false,
  },
  {
    path: '/c/:alias1/step-:step',
    isEmbed: false,
    options: false,
  },
  {
    path: '/c/:alias1/options',
    isEmbed: false,
    options: true,
  },
  {
    path: '/c/:alias1/embed',
    isEmbed: true,
    options: false,
  },
  {
    path: '/c/:alias1/embed/step-:step',
    isEmbed: true,
    options: false,
  },
  {
    path: '/c/:alias1/embed/options',
    isEmbed: true,
    options: true,
  },
  {
    path: '/c/:alias1/:alias2',
    isEmbed: false,
    options: false,
  },
  {
    path: '/c/:alias1/:alias2/step-:step',
    isEmbed: false,
    options: false,
  },
  {
    path: '/c/:alias1/:alias2/options',
    isEmbed: false,
    options: true,
  },
  {
    path: '/c/:alias1/:alias2/embed',
    isEmbed: true,
    options: false,
  },
  {
    path: '/c/:alias1/:alias2/embed/step-:step',
    isEmbed: true,
    options: false,
  },
  {
    path: '/c/:alias1/:alias2/embed/options',
    isEmbed: true,
    options: true,
  },
  {
    path: '/customer/:alias1',
    isEmbed: false,
    options: false,
  },
  {
    path: '/customer/:alias1/step-:step',
    isEmbed: false,
    options: false,
  },
  {
    path: '/customer/:alias1/options',
    isEmbed: false,
    options: true,
  },
  {
    path: '/customer/:alias1/embed',
    isEmbed: true,
    options: false,
  },
  {
    path: '/customer/:alias1/embed/step-:step',
    isEmbed: true,
    options: false,
  },
  {
    path: '/customer/:alias1/embed/options',
    isEmbed: true,
    options: true,
  },
  {
    path: '/customer/:alias1/:alias2',
    isEmbed: false,
    options: false,
  },
  {
    path: '/customer/:alias1/:alias2/step-:step',
    isEmbed: false,
    options: false,
  },
  {
    path: '/customer/:alias1/:alias2/options',
    isEmbed: false,
    options: true,
  },
  {
    path: '/customer/:alias1/:alias2/embed',
    isEmbed: true,
    options: false,
  },
  {
    path: '/customer/:alias1/:alias2/embed/step-:step',
    isEmbed: true,
    options: false,
  },
  {
    path: '/customer/:alias1/:alias2/embed/options',
    isEmbed: true,
    options: true,
  },
];
