import React from "react";
import PropTypes from "prop-types";
import Helmet from "react-helmet";

const activeEnv = process.env.REACT_APP_STAGE || process.env.NODE_ENV || "dev";

function SEO({ description, lang, meta, metaImage, title, pathname }) {
  const site = {
    siteMetadata: {
        title: `Homewise`,
        description: `Getting a great mortgage is easy with Homewise. A simple mortgage process, tailored to save you money. Apply in minutes and we'll do the rest of the work for you!`,
        siteUrl: activeEnv !== "prod" ? `https://my.${activeEnv}.thinkhomewise.com` : `https://my.thinkhomewise.com`,
      },
  }

  const metaTitle = title || site.siteMetadata.title
  const metaDescription = description || site.siteMetadata.description
  const image = `${site.siteMetadata.siteUrl}/${metaImage ? metaImage : 'social_v2.png'}`
  const canonical = pathname ? `${site.siteMetadata.siteUrl}${pathname}` : null
  const fbAppId = (process.env.FACEBOOK_APP_ID) ? process.env.FACEBOOK_APP_ID : "1175830339216122"

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={metaTitle}
      titleTemplate={`%s | ${site.siteMetadata.title}`}
      link={
        canonical
          ? [
              {
                rel: "canonical",
                href: canonical,
              },
            ]
          : []
      }
      meta={[
        {
          name: `description`,
          content: metaDescription,
        },
        {
          property: `og:site_name`,
          content: "Homewise",
        },
        {
          property: `og:title`,
          content: title,
        },
        {
          property: `og:description`,
          content: metaDescription,
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          property: `og:url`,
          content: canonical,
        },
        {
          property: `fb:app_id`,
          content: fbAppId,
        },
        {
          name: `twitter:card`,
          content: `summary`,
        },
        {
          name: `twitter:creator`,
          content: site.siteMetadata.author,
        },
        {
          name: `twitter:title`,
          content: title,
        },
        {
          name: `twitter:description`,
          content: metaDescription,
        },
        {
          name: `twitter:url`,
          content: canonical,
        },
      ]
        .concat(
          image
            ? [
                {
                  property: "og:image",
                  content: image,
                },
                {
                  property: "og:image:width",
                  content: image.width,
                },
                {
                  property: "og:image:height",
                  content: image.height,
                },
                {
                  name: "twitter:card",
                  content: "summary_large_image",
                },
                {
                  name: "twitter:image",
                  content: image,
                },
              ]
            : [
                {
                  name: "twitter:card",
                  content: "summary",
                },
              ]
        )
        .concat(meta)}
    />
  )
}

SEO.defaultProps = {
  lang: `en`,
  meta: [],
  description: ``,
}

SEO.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string.isRequired,
  image: PropTypes.shape({
    src: PropTypes.string.isRequired,
    height: PropTypes.number.isRequired,
    width: PropTypes.number.isRequired,
  }),
  pathname: PropTypes.string,
}

export default SEO;
