// store.js
import React, { createContext, useReducer } from 'react';

const initialState = {
  loggedIn: false,
  hasHomewise: false,
  inProgress: false,
  modalSeen: false,
  financeType: '',
  purchaseHorizonSimple: '',
  closingDate: null,
  financingVisible: false,
  conditionalFinancing: 'U',
  firstTimeHomeBuyer: '',
  propertyInfoOpen: false,
  propertyInfoAddress: '',
  propertyInfoGeoUpdate: false,
  propertyInfoLat: '',
  propertyInfoLng: '',
  propertyInfoAddressOnly: '',
  propertyInfoUnit: '',
  propertyInfoCity: '',
  propertyInfoProvince: '',
  propertyInfoCountry: 'CA',
  propertyInfoPostalCode: '',
  propertyDetailsOwnership: '',
  propertyDetailsPayment: '',
  propertyDetailsOther: false,
  propertyDetailsOtherCount: '',
  propertyDetailsMonth: '',
  propertyDetailsMoveIn: null,
  propertyType: '',
  propertyLocked: false,
  propertyCurrentLocked: false,
  propertyCoappLocked: false,
  otherProperties: '',
  currentMortgageBalance: '',
  currentMortgagePrice: '',
  currentMortgageBorrowingMore: '',
  currentMortgageBorrow: '',
  currentMortgageRate: '',
  currentMortgageRateType: '',
  currentMortgageOrigDp: false,
  currentMortgageLender: '',
  currentMortgageOriginalInsured: '',
  currentOpen: false,
  currentOutCanada: false,
  currentAddress: '',
  currentGeoUpdate: false,
  currentLat: '',
  currentLng: '',
  currentAddressOnly: '',
  currentUnit: '',
  currentCity: '',
  currentProvince: '',
  currentPostalCode: '',
  currentCountry: 'CA',
  expectedPriceTotal: '',
  expectedPriceDpCash: '',
  expectedPriceDpPercent: '',
  expectedPriceOtherOffer: false,
  expectedPriceOtherRate: '',
  lenderName: '',
  propertyPurpose: '',
  userDetailsFirstName: '',
  userDetailsLastName: '',
  userDetailsEmail: '',
  userDetailsPhone: '',
  userDetailsDob: null,
  userDetailsDobMonth: '',
  userDetailsDobDay: '',
  userDetailsDobYear: '',
  userDetailsMaritalStatus: '',
  userDetailsCitizenStatus: '',
  userDetailsPassword: '',
  userDetailsPasswordConfirm: '',
  employmentDetailsStatus: '',
  employmentDetailsMonth: '',
  employmentDetailsYear: '',
  employmentDetailsStartDate: '',
  employmentDetailsEmployer: '',
  employmentDetailsJobTitle: '',
  employmentDetailsCompensationType: '',
  employmentDetailsAnnualIncome: '',
  employmentDetailsAnticipatedBonus: '',
  employmentDetailsOtherIncome: '',
  employmentDetailsOtherStatus: '',
  employmentDetailsOtherAnnualIncome: '',
  prevEmploymentDetailsStatus: '',
  prevEmploymentDetailsMonth: '',
  prevEmploymentDetailsYear: '',
  prevEmploymentDetailsStartDate: '',
  prevEmploymentDetailsEmployer: '',
  prevEmploymentDetailsJobTitle: '',
  prevEmploymentDetailsCompensationType: '',
  prevEmploymentDetailsAnnualIncome: '',
  prevEmploymentDetailsAnticipatedBonus: '',
  prevEemploymentDetailsOtherIncome: '',
  prevEmploymentDetailsOtherStatus: '',
  coAppType: '',
  coappFirstName: '',
  coappLastName: '',
  coappEmail: '',
  coappPhone: '',
  coappDobMonth: '',
  coappDobDay: '',
  coappDobYear: '',
  coappDob: null,
  coappCitizenStatus: '',
  coappMaritalStatus: '',
  coappRelation: '',
  coappLiveWith: true,
  coEmploymentDetailsStatus: '',
  coEmploymentDetailsMonth: '',
  coEmploymentDetailsYear: '',
  coEmploymentDetailsStartDate: '',
  coEmploymentDetailsEmployer: '',
  coEmploymentDetailsJobTitle: '',
  coEmploymentDetailsCompensationType: '',
  coEmploymentDetailsAnnualIncome: '',
  coEmploymentDetailsAnticipatedBonus: '',
  coEmploymentDetailsOtherIncome: '',
  coEmploymentDetailsOtherStatus: '',
  coEmploymentDetailsOtherAnnualIncome: '',
  coPrevEmploymentDetailsStatus: '',
  coPrevEmploymentDetailsMonth: '',
  coPrevEmploymentDetailsYear: '',
  coPrevEmploymentDetailsStartDate: '',
  coPrevEmploymentDetailsEmployer: '',
  coPrevEmploymentDetailsJobTitle: '',
  coPrevEmploymentDetailsCompensationType: '',
  coPrevEmploymentDetailsAnnualIncome: '',
  coPrevEmploymentDetailsAnticipatedBonus: '',
  coPrevEmploymentDetailsOtherIncome: '',
  coPrevEmploymentDetailsOtherStatus: '',
  coappPropertyInfoOpen: false,
  coappPropertyInfoAddress: '',
  coappPropertyInfoGeoUpdate: false,
  coappPropertyInfoLat: '',
  coappPropertyInfoLng: '',
  coappPropertyInfoAddressOnly: '',
  coappPropertyInfoUnit: '',
  coappPropertyInfoCity: '',
  coappPropertyInfoProvince: '',
  coappPropertyInfoPostalCode: '',
  coappPropertyInfoCountry: 'CA',
  futurePlanningOwnershipTime: '',
  financesSavings: '',
  financesInvestments: '',
  financesSavingsCo: '',
  financesInvestmentsCo: '',
  financesVehicles: '',
  financesDebt: '',
  incomeIncrease: '',
  growing_family: '',
  lowerPayments: '',
  lowestRate: '',
  high_risk: '',
  ownershipPeriod: '',
  creditScore: '',
  realtor: '', // This was left hear for legacy reasons
  introduceRealtor: '',
  comment: '',
  marketingOptin: false,
  application: {},
  userSchema: {},
  signer: {},
  authRegister: {},
  autoPassword: '',
  authLogin: {},
  authEmail: {},
  authReset: {},
  user: {},
  app: {},
  coUser: {},
  isAddressOpen: false,
  isAddressCurrentOpen: false,
  isAddressCoappOpen: false,
  isInternalRedirect: false,
  inFlowReset: false,
  // following for the new partner url scheme
  partnerPath: '',
  group: '',
  alias1: '',
  alias2: '',
  product: '',
  uiTracking: {},
  isPartnerValid: false,
  partner: {},
  referrer: {},
  embedMode: false,
  // new urlScheme objects end here
};
const store = createContext(initialState);
const { Provider } = store;

const reducer = (state, action) => {
  // console.log("prevState: ", state);
  // console.log("action: ", action);
  if (action.type === 'LOG_OUT') {
    return {
      ...initialState,
    };
  } else if (action.type === 'LOG_IN_RESET') {
    return {
      ...initialState,
      userDetailsFirstName: state.userDetailsFirstName,
      userDetailsLastName: state.userDetailsLastName,
      userDetailsDob: state.userDetailsDob,
      userDetailsEmail: state.userDetailsEmail,
      userDetailsPhone: state.userDetailsPhone,
      userDetailsMaritalStatus: state.userDetailsMaritalStatus,
      userDetailsCitizenStatus: state.userDetailsCitizenStatus,
      partnerPath: state.partnerPath,
      group: state.group,
      alias1: state.alias1,
      alias2: state.alias2,
      uiTracking: state.uiTracking,
      isPartnerValid: state.isPartnerValid,
      partner: state.partner,
      referrer: state.referrer,
      embedMode: state.embedMode,
      isInternalRedirect: state.isInternalRedirect,
      app: {
        referrer: state.app.referrer,
      },
    };
  } else if (action.type === 'EMPLOYMENT_RESET') {
    return {
      ...state,
      employmentDetailsStartDate: '',
      employmentDetailsEmployer: '',
      employmentDetailsJobTitle: '',
      employmentDetailsCompensationType: '',
      employmentDetailsAnnualIncome: '',
      employmentDetailsAnticipatedBonus: '',
      employmentDetailsOtherIncome: '',
      employmentDetailsOtherStatus: '',
      employmentDetailsOtherAnnualIncome: '',
    };
  } else if (action.type === 'COEMPLOYMENT_RESET') {
    return {
      ...state,
      coEmploymentDetailsStartDate: '',
      coEmploymentDetailsEmployer: '',
      coEmploymentDetailsJobTitle: '',
      coEmploymentDetailsCompensationType: '',
      coEmploymentDetailsAnnualIncome: '',
      coEmploymentDetailsAnticipatedBonus: '',
      coEmploymentDetailsOtherIncome: '',
      coEmploymentDetailsOtherStatus: '',
      coEmploymentDetailsOtherAnnualIncome: '',
    };
  } else {
    return {
      ...state,
      [action.type]: action.newVal,
    };
  }

  // switch (action.type) {
  //   case action.type:
  //     return {
  //       ...state,
  //       [action.type]: action.newVal,
  //     };
  //   default:
  //     throw new Error();
  // }
};

export function useCallReducer() {
  const [state, dispatch] = useReducer(reducer, initialState);
  return { state, dispatch };
}

const StateProvider = ({ children }) => {
  return <Provider value={useCallReducer()}>{children}</Provider>;
};

export { store, StateProvider };
