// import React, { useContext } from "react";
// import UserContext from "../UserContext";
import React from 'react';

import { Route, Redirect } from 'react-router-dom';

import { DashboardProvider } from '../store/dashboard/Store';

const PrivateRoute = ({ component: Component, children, ...rest }) => {
  const activeSession = localStorage.getItem('activeSession') === 'true';
  return (
    <Route
      {...rest}
      render={({ props, location }) =>
        activeSession ? (
          <DashboardProvider>
            <Route {...rest} render={(props) => <Component {...props} />} />
          </DashboardProvider>
        ) : (
          (
            <Redirect
              to={{
                pathname: '/sign-in',
                search: !!location
                  ? '?redirect=' + encodeURIComponent(location?.pathname)
                  : '',
                state: { from: location },
              }}
            />
          )
        )
      }
    />
  );
};

export default PrivateRoute;
