import React from 'react';
import { withTranslation } from 'react-i18next';
import { withStyles } from '@material-ui/core/styles';

import Link from '@material-ui/core/Link';
// import InstagramIcon from "@material-ui/icons/Instagram";
// import TwitterIcon from "@material-ui/icons/Twitter";
// import LinkedInIcon from "@material-ui/icons/LinkedIn";
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
// import IconButton from "@material-ui/core/IconButton";

import { Container } from '@material-ui/core';

import logo from '../imgs/whiteLogo.svg';
import flag from '../imgs/cdn-flag.png';

const styles = (theme) => ({
  appBar: {
    padding: '1.5rem 0',
    boxShadow: 'none',
    background: theme.palette.secondary.main,
    // top: "auto",
    // bottom: "42px",
    display: `flex`,
    marginTop: `auto`,
    borderBottom: `1rem solid ` + theme.palette.secondary.dark,
    transform: `translate3d(0,0,0)`,
  },
  toolBar: {
    display: `flex`,
    flexDirection: `column`,
    fontSize: `.9rem`,
    [theme.breakpoints.up('md')]: {
      flexDirection: `row`,
    },
    justifyContent: `space-between`,
    minHeight: '42px',
  },
  link: {
    color: `#fff`,
  },
  ftrLeft: {
    width: `100%`,
    display: `flex`,
    alignItems: `center`,
    flexDirection: `column`,
    textAlign: `center`,
    [theme.breakpoints.up('md')]: {
      width: `50%`,
      textAlign: `left`,
      alignItems: `flex-start`,
    },
  },
  ftrRight: {
    display: `flex`,
    alignItems: `center`,
    flexDirection: `column`,
    textAlign: `center`,
    [theme.breakpoints.up('md')]: {
      flexDirection: `row`,
      marginTop: `-3.5rem`,
    },
  },
  ftrLegal: {
    lineHeight: `1rem`,
    marginLeft: `0rem`,
    marginTop: `0rem`,
    marginBottom: `1rem`,
    [theme.breakpoints.up('md')]: {},
  },
});

class Footer extends React.Component {
  render() {
    const { classes } = this.props;
    const { t } = this.props;
    return (
      <AppBar className={classes.appBar} position="static">
        <Container>
          <Toolbar className={classes.toolBar}>
            <div className={classes.ftrLeft}>
              <div
                style={{
                  display: `flex`,
                  flexDirection: `column`,
                  marginTop: `1rem`,
                }}
              >
                {/* <small className="sm-font">{t("poweredby")}</small> */}
                <img src={logo} style={{ margin: `.5rem 0 1rem` }} alt="Homewise" width="140" height="26.5" />
              </div>
              <small className={classes.ftrLegal}>
                <img src={flag} width="22" height="10" alt="Canadian Flag" /> {t('legal.0')}
              </small>
              <small className={classes.ftrLegal}>
                &copy; {new Date().getFullYear()} {t('legal.1')}
                <br />
                {t('legal.2')}
                <br />
                {t('legal.3')}
              </small>
            </div>

            <div className={classes.ftrRight}>
              <ul style={{ padding: `0`, margin: `0` }}>
                <li style={{ display: `inline`, padding: `0rem .75rem` }}>
                  <Link href={t('questions:comments.touUrl')} className={classes.link} target="_blank" rel="noopener noreferrer">
                    {t('cta.terms')}
                  </Link>
                </li>
                <li style={{ display: `inline`, padding: `0rem .75rem` }}>
                  <Link href={t('questions:comments.ppUrl')} className={classes.link} target="_blank" rel="noopener noreferrer">
                    {t('cta.privacy')}
                  </Link>
                </li>
                <li style={{ display: `inline`, padding: `0rem .75rem` }}>
                  <button
                    className={classes.link}
                    id="accessibilityWidget"
                    style={{
                      textDecoration: `none`,
                      background: `transparent`,
                      border: `0`,
                      cursor: `pointer`,
                      fontFamily: `Merriweather Sans`,
                      fontWeight: `300`,
                      padding: `0`,
                    }}
                  >
                    {t('cta.accessible')}
                  </button>
                </li>
                <li style={{ display: 'none', padding: `0rem .75rem` }}>
                  <button
                    className={classes.link}
                    id="accessibilityWidget1"
                    style={{
                      textDecoration: `none`,
                      background: `transparent`,
                      border: `0`,
                      cursor: `pointer`,
                      fontFamily: `Merriweather Sans`,
                      fontWeight: `300`,
                      padding: `0`,
                    }}
                  >
                    {t('cta.accessible')}
                  </button>
                </li>
              </ul>
              {/* <ul style={{ padding: `0`, margin: `0` }}>
                <li style={{ display: `inline` }}>
                  <IconButton
                    aria-label="instagram"
                    href="https://www.instagram.com/homewise_/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <InstagramIcon style={{ color: `#fff` }} />
                  </IconButton>
                </li>
                <li style={{ display: `inline` }}>
                  <IconButton
                    aria-label="twitter"
                    href="https://twitter.com/hihomewise/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <TwitterIcon style={{ color: `#fff` }} />
                  </IconButton>
                </li>
                <li style={{ display: `inline` }}>
                  <IconButton
                    aria-label="linked in"
                    href="https://ca.linkedin.com/company/homewise-solutions"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <LinkedInIcon style={{ color: `#fff` }} />
                  </IconButton>
                </li>
              </ul> */}
            </div>
          </Toolbar>
        </Container>
      </AppBar>
    );
  }
}

export default withStyles(styles)(withTranslation('common')(Footer));
