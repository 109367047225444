import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
// import LinearProgress from '@material-ui/core/LinearProgress';
import CircularProgress from '@material-ui/core/CircularProgress';

const useStyles = makeStyles({
  root: {
    width: '100%',
    padding: `1rem 0`,
    textAlign: `center`,
  },
});

const useStylesHW = makeStyles((theme) => ({
  root: {
    position: 'relative',
    textAlign: `center`,
  },
  bottom: {
    color: theme.palette.grey[theme.palette.type === 'light' ? 200 : 700],
  },
  top: {
    color: '#007bff',
    animationDuration: '550ms',
    position: 'absolute',
    left: `calc(50% - 12px)`,
  },
  circle: {
    strokeLinecap: 'round',
  },
}));

function HWCircularProgress(props) {
  const classes = useStylesHW();

  return (
    <div className={classes.root}>
      <CircularProgress
        variant="determinate"
        className={classes.bottom}
        size={24}
        thickness={4}
        {...props}
        value={100}
      />
      <CircularProgress
        variant="indeterminate"
        disableShrink
        className={classes.top}
        classes={{
          circle: classes.circle,
        }}
        size={24}
        thickness={4}
        {...props}
      />
    </div>
  );
}

export default function LinearBuffer() {
  const classes = useStyles();
  // const [progress, setProgress] = React.useState(0);
  // const [buffer, setBuffer] = React.useState(10);

  // const progressRef = React.useRef(() => {});
  // React.useEffect(() => {
  //   progressRef.current = () => {
  //     if (progress > 100) {
  //       setProgress(0);
  //       setBuffer(10);
  //     } else {
  //       const diff = Math.random() * 10;
  //       const diff2 = Math.random() * 10;
  //       setProgress(progress + diff);
  //       setBuffer(progress + diff + diff2);
  //     }
  //   };
  // });

  // React.useEffect(() => {
  //   const timer = setInterval(() => {
  //     progressRef.current();
  //   }, 500);

  //   return () => {
  //     clearInterval(timer);
  //   };
  // }, []);

  return (
    <div className={classes.root}>
      {/* <LinearProgress variant="buffer" value={progress} valueBuffer={buffer} /> */}
      {/* <CircularProgress size={20} thickness={2} /> */}
      <HWCircularProgress />
    </div>
  );
}
