export const realty = [
  {
    path: '/ra/:alias1',
    isEmbed: false,
    options: false,
  },
  {
    path: '/ra/:alias1/step-:step',
    isEmbed: false,
    options: false,
  },
  {
    path: '/ra/:alias1/options',
    isEmbed: false,
    options: true,
  },
  {
    path: '/ra/:alias1/embed',
    isEmbed: true,
    options: false,
  },
  {
    path: '/ra/:alias1/embed/step-:step',
    isEmbed: true,
    options: false,
  },
  {
    path: '/ra/:alias1/embed/options',
    isEmbed: true,
    options: true,
  },
  {
    path: '/ra/:alias1/:alias2',
    isEmbed: false,
    options: false,
  },
  {
    path: '/ra/:alias1/:alias2/step-:step',
    isEmbed: false,
    options: false,
  },
  {
    path: '/ra/:alias1/:alias2/options',
    isEmbed: false,
    options: true,
  },
  {
    path: '/ra/:alias1/:alias2/embed',
    isEmbed: true,
    options: false,
  },
  {
    path: '/ra/:alias1/:alias2/embed/step-:step',
    isEmbed: true,
    options: false,
  },
  {
    path: '/ra/:alias1/:alias2/embed/options',
    isEmbed: true,
    options: true,
  }
];
