import React, { useContext, useState, lazy, Suspense } from 'react';

import { store } from '../../store/app/Store';
import { usePartnerLookup } from 'utils/hooks/usePartnerLookup';

import { withTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';

import Nav from 'components/Nav';
import Landing from 'pages/Landing/Landing';
import EmbedFooter from 'components/EmbedFooter';

import { makeStyles } from '@material-ui/core/styles';
import LinearProgress from '@material-ui/core/LinearProgress';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';

const Steps = lazy(() => import('pages/Landing/Steps'));

const useStyles = makeStyles((theme) => ({
  stepper: {
    width: `50%`,
    margin: theme.spacing(1.5, 'auto'),
    [theme.breakpoints.down('sm')]: {
      margin: 0,
      padding: 0,
    },
  },
}));

const Controller = (props) => {
  const { options, group, routeStep, alias1, alias2, isEmbed, appLoading } = props;
  const classes = useStyles();

  const globalState = useContext(store);
  const { dispatch } = globalState;

  const [fromLanding, setfromLanding] = useState(false);

  const [activeStep, setActiveStep] = useState(routeStep === 0 ? 1 : routeStep > 23 ? 1 : routeStep - 1);

  usePartnerLookup(globalState, group, dispatch, alias1, alias2);

  return (
    <>
      {!isEmbed && <Nav fromController={true} setActiveStep={setActiveStep} dispatch={dispatch} showFlag={true} />}

      {!!activeStep || activeStep === 0 ? (
        <Suspense
          fallback={
            <Container
              disableGutters
              style={{
                display: `flex`,
                flexDirection: `column`,
                flexGrow: `1`,
              }}
            >
              <Grid container justifyContent="center">
                <Grid item xs={12} style={{ padding: `.5rem 0` }}>
                  <div>
                    <LinearProgress className={classes.stepper} />
                  </div>
                </Grid>
              </Grid>
            </Container>
          }
        >
          <Steps
            group={group}
            routeStep={routeStep}
            alias1={alias1}
            alias2={alias2}
            isEmbed={isEmbed}
            fromLanding={fromLanding}
            activeStep={activeStep}
            setActiveStep={setActiveStep}
          />
        </Suspense>
      ) : (
        <>
          <Landing
            options={options}
            isEmbed={isEmbed}
            setActiveStep={setActiveStep}
            setfromLanding={setfromLanding}
            isPartner={group && alias1}
            appLoading={appLoading}
          />
          {isEmbed && <EmbedFooter />}
        </>
      )}
    </>
  );
};

export default withRouter(withTranslation('questions')(Controller));
