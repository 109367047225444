import React from 'react';
import { withTranslation } from 'react-i18next';
import { withStyles } from '@material-ui/core/styles';
import { Grid, Container } from '@material-ui/core';

import Link from '@material-ui/core/Link';

const styles = (theme) => ({
  ftrLeft: {
    display: `flex`,
    alignItems: `left`,
    flexDirection: `row`,
    textAlign: `left`,
  },
  ftrRight: {
    display: `flex`,
    alignItems: `right`,
    flexDirection: `row`,
    textAlign: `right`,
  },
  linkGroup: {
    textAlign: `center`,
    marginTop: `10px`,
    [theme.breakpoints.up('sm')]: {
      textAlign: `left`,
    },
  },
  link: {
    color: `#147bc9`,
    textDecoration: 'none',
    fontWeight: '400',
  },
  lockup: {
    textAlign: `center`,
    fontSize: '.7em',
    color: '#054d85',
    verticalAlign: 'text-bottom',
    [theme.breakpoints.up('sm')]: {
      textAlign: 'right',
    },
  },
});

function PoweredBy(props) {
  // console.log("embed footer Url search", window.location.search);
  // const url = 'https://thinkhomewise.com/';
  const { classes } = props;
  return (
    <p className={classes.lockup}>
      {/* <a
        href={url}
        target="_blank"
        rel="noopener noreferrer"
        style={{ textDecoration: 'none', color: '#054d85' }}
      > */}
      powered by &nbsp;
      <svg id="nav_logo" xmlns="http://www.w3.org/2000/svg" width="90" height="15" viewBox="-5 0 48.98 48" preserveAspectRatio="xMinYMin meet">
        <defs></defs> <title>Homewise</title>
        <path
          style={{ fill: '#317bc0' }}
          d="M71.72,68.28H64.87V20.4h6.85V40.92H79.5V20.4h7V68.28h-7V47.76H71.72Z"
          transform="translate(-20.5 -19.85)"
        ></path>
        <path
          style={{ fill: '#317bc0' }}
          d="M93.35,31.89c0-7.66,3.67-12,10.39-12s10.39,4.38,10.39,12v24.9c0,7.66-3.67,12-10.39,12s-10.39-4.38-10.39-12Zm6.85,25.38c0,3.42,1.37,4.72,3.55,4.72s3.55-1.3,3.55-4.72V31.41c0-3.42-1.37-4.72-3.55-4.72s-3.55,1.3-3.55,4.72Z"
          transform="translate(-20.5 -19.85)"
        ></path>
        <path
          style={{ fill: '#317bc0' }}
          d="M135.56,54.4l4.67-34h9.52V68.28h-6.47V33.94l-4.73,34.34h-6.47L127,34.42V68.28h-6V20.4h9.52Z"
          transform="translate(-20.5 -19.85)"
        ></path>
        <path
          style={{ fill: '#317bc0' }}
          d="M163.87,40.58h9.4v6.84h-9.4v14h11.83v6.84H157V20.4h18.67v6.84H163.87Z"
          transform="translate(-20.5 -19.85)"
        ></path>
        <path
          style={{ fill: '#317bc0' }}
          d="M197.48,42.77,195,68.28h-9.4L180.73,20.4h6.66l3.67,37.76,3.3-37.76H201l3.42,38,3.55-38h6l-4.85,47.88H200Z"
          transform="translate(-20.5 -19.85)"
        ></path>
        <path style={{ fill: '#317bc0' }} d="M220.24,20.4h6.85V68.28h-6.85Z" transform="translate(-20.5 -19.85)"></path>
        <path
          style={{ fill: '#317bc0' }}
          d="M243.85,19.85c6.66,0,10.08,4.38,10.08,12v1.5h-6.47v-2c0-3.42-1.24-4.72-3.42-4.72s-3.42,1.3-3.42,4.72c0,9.85,13.38,11.7,13.38,25.38,0,7.66-3.49,12-10.21,12s-10.21-4.38-10.21-12V53.85h6.47v3.42c0,3.42,1.37,4.65,3.55,4.65s3.55-1.23,3.55-4.65c0-9.85-13.38-11.7-13.38-25.38C233.76,24.23,237.19,19.85,243.85,19.85Z"
          transform="translate(-20.5 -19.85)"
        ></path>
        <path
          style={{ fill: '#317bc0' }}
          d="M267.37,40.58h9.4v6.84h-9.4v14H279.2v6.84H260.52V20.4H279.2v6.84H267.37Z"
          transform="translate(-20.5 -19.85)"
        ></path>{' '}
        <g id="Ground">
          <path style={{ fill: '#3db54a' }} d="M20.53,61.51H51.16v6.84H20.53Z" transform="translate(-20.5 -19.85)"></path>{' '}
        </g>{' '}
        <g id="House">
          {' '}
          <polygon
            style={{ fill: '#5dc9e4' }}
            points="15.35 0.6 0 14.28 0 31.39 11.94 31.39 11.94 21.06 18.72 21.06 18.72 31.39 30.7 31.39 30.7 14.28 15.35 0.6"
          ></polygon>
        </g>
      </svg>
      {/* </a> */}
    </p>
  );
}

class EmbedFooter extends React.Component {
  render() {
    const { classes, t } = this.props;
    return (
      <Container disableGutters>
        <Grid container direction="row" justifyContent="center">
          <Grid item container sm={8} xs={10}>
            <Grid item xs={12} sm={6}>
              <p className={classes.linkGroup}>
                <Link href={t('questions:comments.touUrl')} className={classes.link} target="_blank" rel="noopener noreferrer">
                  {t('cta.terms')}
                </Link>
                &nbsp; &nbsp;
                <Link href={t('questions:comments.ppUrl')} className={classes.link} target="_blank" rel="noopener noreferrer">
                  {t('cta.privacy')}
                </Link>
              </p>
            </Grid>
            <Grid item xs={12} sm={6}>
              <PoweredBy classes={classes} />
            </Grid>
          </Grid>
        </Grid>
      </Container>
    );
  }
}

export default withStyles(styles)(withTranslation('common')(EmbedFooter));
