const envs = {
  dev: {
    cognito: {
      USER_POOL_ID: process.env.REACT_APP_COGNITO_POOL_ID || 'us-east-2_p79oYNfaI',
      APP_CLIENT_ID: process.env.REACT_APP_COGNITO_CLIENT_ID || '7imr2gkha8h792i9107ea0e708',
    },
    google: {
      MAPS: 'AIzaSyC3PIjg9UncoxPvwcNbYV_gdjIY46g6VpA',
      GTM_ID: 'GTM-N7869Z3',
      GTM_AUTH: 'yXGCj9M1o2PRT_pADcFTIA',
      GTM_PREV: 'env-3',
    },
    hoa: 'https://my.dev.thinkhomewise.com',
    api: {
      API_HOST: 'https://api.dev.thinkhomewise.com',
      APP: 'https://api.dev.thinkhomewise.com/customer/application',
      USER: 'https://api.dev.thinkhomewise.com/customer/user',
      PARTNER: 'https://api.dev.thinkhomewise.com/partner/brand',
      DASHBOARD: 'https://api.dev.thinkhomewise.com/customer/dashboard',
      BASE: 'https://api.dev.thinkhomewise.com/customer',
      REFERRALS: 'https://api.dev.thinkhomewise.com/customer/user/referrals',
    },
    optOut: ['Homewise Solutions Inc.X'],
  },
  staging: {
    cognito: {
      USER_POOL_ID: process.env.REACT_APP_COGNITO_POOL_ID || 'us-east-2_DWQCKvXCo',
      APP_CLIENT_ID: process.env.REACT_APP_COGNITO_CLIENT_ID || '5439n54p7pck0f59ou1jbd659o',
    },
    google: {
      MAPS: 'AIzaSyDcbYeNZjIE1dVkQU9DGZ8YwUTvo96ONPs',
      GTM_ID: 'GTM-N7869Z3',
      GTM_AUTH: 'ls1uWtMj6lIRiYUGL5nTAw',
      GTM_PREV: 'env-4',
    },
    hoa: 'https://my.staging.thinkhomewise.com',
    api: {
      API_HOST: 'https://api.staging.thinkhomewise.com',
      APP: 'https://api.staging.thinkhomewise.com/customer/application',
      USER: 'https://api.staging.thinkhomewise.com/customer/user',
      PARTNER: 'https://api.staging.thinkhomewise.com/partner/brand',
      DASHBOARD: 'https://api.staging.thinkhomewise.com/customer/dashboard',
      BASE: 'https://api.staging.thinkhomewise.com/customer',
      REFERRALS: 'https://api.staging.thinkhomewise.com/customer/user/referrals',
    },
    optOut: ['nerdwallet'],
  },
  prod: {
    cognito: {
      USER_POOL_ID: process.env.REACT_APP_COGNITO_POOL_ID || 'us-east-2_Jw9soIcjP',
      APP_CLIENT_ID: process.env.REACT_APP_COGNITO_CLIENT_ID || '2g0l19pv9h4gteot4g5f56p9eo',
    },
    google: {
      MAPS: 'AIzaSyC74aE0g-t2UUWxyw0O-wwl3chLC4Rwy6A',
      GTM_ID: 'GTM-N7869Z3',
      GTM_AUTH: 'dPyA8GakeiXHXQt0mZazyw',
      GTM_PREV: 'env-1',
    },
    hoa: 'https://my.thinkhomewise.com',
    api: {
      API_HOST: 'https://api.thinkhomewise.com',
      APP: 'https://api.thinkhomewise.com/customer/application',
      USER: 'https://api.thinkhomewise.com/customer/user',
      PARTNER: 'https://api.thinkhomewise.com/partner/brand',
      DASHBOARD: 'https://api.thinkhomewise.com/customer/dashboard',
      BASE: 'https://api.thinkhomewise.com/customer',
      REFERRALS: 'https://api.thinkhomewise.com/customer/user/referrals',
    },
    optOut: ['nerdwallet'],
  },
};

const config = {
  ...envs[process.env.REACT_APP_STAGE],
};

export default config;
