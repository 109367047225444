export const base = [
  {
    path: '/',
    isEmbed: false,
    options: false,
  },
  {
    path: '/options',
    isEmbed: false,
    options: true,
  },
  {
    path: '/step-:step',
    isEmbed: false,
    options: false,
  },
];
