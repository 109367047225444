import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import common_en from './translations/en/common.json';
import common_fr from './translations/fr/common.json';

import questions_en from './translations/en/questions.json';
import questions_fr from './translations/fr/questions.json';

import login_en from './translations/en/login.json';
import login_fr from './translations/fr/login.json';

import getstarted_en from './translations/en/getstarted.json';
import getstarted_fr from './translations/fr/getstarted.json';

import autherrors_en from './translations/en/authErrors.json';
import autherrors_fr from './translations/fr/authErrors.json';

import dashboard_en from './translations/en/dashboard.json';
import dashboard_fr from './translations/fr/dashboard.json';

import status_en from './translations/en/status.json';
import status_fr from './translations/fr/status.json';

import category_en from './translations/en/category.json';
import category_fr from './translations/fr/category.json';

// the translations
const resources = {
  en: {
    common: common_en,
    questions: questions_en,
    login: login_en,
    getstarted: getstarted_en,
    authErrors: autherrors_en,
    dashboard: dashboard_en,
    status: status_en,
    category: category_en,
  },
  fr: {
    common: common_fr,
    questions: questions_fr,
    login: login_fr,
    getstarted: getstarted_fr,
    authErrors: autherrors_fr,
    dashboard: dashboard_fr,
    status: status_fr,
    category: category_fr,
  },
};

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    languages: ['en', 'fr'],
    ns: ['common', 'questions', 'login', 'getstarted', 'authErrors'],
    defaultNS: 'common',
    resources,
    lng: 'en',

    // keySeparator: false, // we do not use keys in form messages.welcome

    interpolation: {
      escapeValue: false, // react already safes from xss
    },
  });

export default i18n;
