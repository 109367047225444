import React, { useContext, lazy, Suspense } from 'react';
import UserContext from '../store/app/Context';
import { store } from '../store/app/Store';
import i18next from '../i18n';
import Config from '../config';

import { withStyles } from '@material-ui/core/styles';
import MenuIcon from '@material-ui/icons/Menu';
import { AppBar, Toolbar, Button, Container, Hidden } from '@material-ui/core';

import logoHouse from '../imgs/homewise-house.png';
import logo from '../imgs/homewise-logo.png';
import leaf from '../imgs/cdn-leaf.png';

import { withRouter, Link } from 'react-router-dom';

const MobileNavLazy = lazy(() => import('components/MobileNav'));

const styles = (theme) => ({
  appBar: {
    padding: '0px',
    boxShadow: 'none',
    background: '#fff',
    display: `flex`,
    flexDirection: `row`,
    justifyContent: `space-between`,
    transform: `translate3d(0,0,0)`,
  },
  flexContainer: {
    display: `flex`,
    flexDirection: `row`,
    justifyContent: `space-between`,
  },
  logo: {
    alignSelf: `center`,
    marginLeft: `20px`,
    // [theme.breakpoints.down("sm")]: {
    //   marginLeft: `5px`,
    // },
  },
  toolBar: {
    width: `40%`,
    display: 'flex',
    flexDirection: `row`,
    minHeight: '42px',
    fontFamily: `Lato`,
    justifyContent: `flex-end`,
  },
  toolBar2: {
    display: 'flex',
    flexDirection: `row`,
    minHeight: '48px',
    [theme.breakpoints.up('lg')]: {
      minHeight: '42px',
    },
  },
  link: {
    textTransform: `none`,
    fontSize: `.75rem`,
    color: `#054d85`,
  },
  businessNameLink: {
    display: `flex`,
    alignSelf: `center`,
    textDecoration: 'none',
    color: theme.palette.primary.dark,
  },
  button: {
    fontSize: `.8rem`,
    textTransform: `none`,
    padding: `2px 20px`,
    borderRadius: `4px`,
    marginTop: `-2px`,
  },
});

const HeaderImage = (props) => {
  const { classes } = props;
  const globalState = useContext(store);

  const disabledCobrand = Config.optOut;

  return (
    <>
      {globalState.state.isPartnerValid ? (
        globalState.state.partner.business ? (
          globalState.state.partner.branding.logo ? (
            <Link
              to={`${globalState.state.partnerPath}`}
              style={{ display: `flex`, alignSelf: `center` }}
            >
              <img
                src={logo}
                className={classes.logo}
                alt="Homewise"
                height="25"
                width="131.47"
              />
              {!disabledCobrand.includes(
                globalState.state.partner.business.name
              ) && (
                <img
                  src={globalState.state.partner.branding.logo}
                  className={classes.logo}
                  alt=""
                  height="25"
                />
              )}
            </Link>
          ) : globalState.state.partner.business.name ? (
            <Link
              to={`${globalState.state.partnerPath}`}
              className={classes.businessNameLink}
            >
              <Hidden smDown>
                <img
                  src={logo}
                  className={classes.logo}
                  alt="Homewise"
                  height="25"
                  width="131.47"
                />
              </Hidden>
              <Hidden mdUp>
                <img
                  src={logoHouse}
                  className={classes.logo}
                  alt="Homewise"
                  height="25"
                />
              </Hidden>
              {!disabledCobrand.includes(
                globalState.state.partner.business.name
              ) && (
                <p
                  dangerouslySetInnerHTML={{
                    __html: globalState.state.partner.business.name,
                  }}
                  style={{ textDecoration: 'none', paddingLeft: '15px' }}
                />
              )}
            </Link>
          ) : (
            <Link
              to={`${globalState.state.partnerPath}`}
              style={{ display: `flex`, alignSelf: `center` }}
            >
              <img
                src={logo}
                className={classes.logo}
                alt="Homewise"
                height="25"
              />
            </Link>
          )
        ) : (
          <Link
            to={`${globalState.state.partnerPath}`}
            style={{ display: `flex`, alignSelf: `center` }}
          >
            <img
              src={logo}
              className={classes.logo}
              alt="Homewise"
              height="25"
            />
          </Link>
        )
      ) : (
        <Link to={'/'} style={{ display: `flex`, alignSelf: `center` }}>
          <img src={logo} className={classes.logo} alt="Homewise" height="25" />
        </Link>
      )}
    </>
  );
};

class Nav extends React.Component {
  static contextType = UserContext;

  render() {
    const { classes, dispatch } = this.props;

    const { auth, endSession } = this.context;

    const Login = withRouter(({ history }) => (
      <Button
        className={this.props.classes.button}
        onClick={() => {
          history.push('/sign-in');
        }}
        variant={'outlined'}
        color="secondary"
      >
        {i18next.t('common:cta.signin')}
      </Button>
    ));

    const SignOut = withRouter(({ history }) => (
      <Button
        className={this.props.classes.button}
        onClick={() => {
          endSession(() => {
            // history.go(0);
            try {
              localStorage.setItem('activeSession', false);
            } catch (e) {
              console.log('LocalStorage Error');
            }
            if (this.props.fromController) {
              this.props.setActiveStep(null);
            }
            dispatch({ type: 'LOG_OUT' });
            history.push('../');
          });
        }}
        variant={'outlined'}
        color="secondary"
      >
        Sign Out
      </Button>
    ));

    // const Dashboard = withRouter(({ history }) => (
    //   <Button
    //     onClick={() => {
    //       history.push('/dashboard');
    //     }}
    //     variant={'text'}
    //     color="secondary"
    //     className={this.props.classes.button}
    //     style={{ marginRight: `.5rem` }}
    //   >
    //     Dashboard
    //   </Button>
    // ));

    const activeSession = localStorage.getItem('activeSession') === 'true';
    // console.log("LocalStorageSession", activeSession);

    return (
      <>
        <AppBar className={classes.appBar} position="fixed">
          <Container disableGutters className={classes.flexContainer}>
            {this.props.showFlag && (
              <Hidden mdUp>
                <img
                  src={leaf}
                  alt="Across Canada"
                  width="20"
                  height="20"
                  style={{ marginLeft: `1rem`, alignSelf: `center` }}
                />
              </Hidden>
            )}
            <HeaderImage classes={this.props.classes} t={this.props.t} />
            <Hidden smDown>
              <Toolbar className={classes.toolBar}>
                {activeSession || auth.activeSession ? (
                  <>
                    {/* <Dashboard /> */}
                    <SignOut />
                  </>
                ) : (
                  <Login />
                )}
                {this.props.showFlag && (
                  <img
                    src={leaf}
                    alt="Across Canada"
                    width="20"
                    height="20"
                    style={{ marginTop: `-.2rem`, marginLeft: `1rem` }}
                  />
                )}
              </Toolbar>
            </Hidden>
            <Hidden mdUp>
              <Suspense
                fallback={
                  <Button style={{ padding: `10px` }} aria-label={'Menu'}>
                    <MenuIcon color="primary" />
                  </Button>
                }
              >
                <MobileNavLazy
                  activeSession={activeSession}
                  authSession={auth.activeSession}
                />
              </Suspense>
            </Hidden>
          </Container>
        </AppBar>
        <Toolbar className={classes.toolBar2} />
      </>
    );
  }
}

export default withStyles(styles)(Nav);

// export default withRouter(withStyles(styles)(Nav));
