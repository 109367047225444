import React, { useContext, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import { withTranslation } from 'react-i18next';

import TagManager from 'react-gtm-module';

import { store } from '../../store/app/Store';

import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import Hidden from '@material-ui/core/Hidden';

import greeting from '../../imgs/greetings.png';
import getstarted from '../../imgs/get-started-2.png';
import getstartedpartner from '../../imgs/get-started-partner.png';

import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';

import Fade from 'react-reveal/Fade';
import Loading from 'components/Loading2';
import config from '../../config';

const useStyles = makeStyles((theme) => ({
  margin: {
    margin: `10px`,
  },
  embed: {
    fontSize: `.9rem`,
    // marginTop: `20px`,
    padding: `2rem 0px`,
  },
  hero: {
    fontSize: `.9rem`,
    marginTop: `auto`,
    padding: `2rem 0px`,
  },
  centerImg: {
    display: `block`,
    margin: `0 auto`,
    maxWidth: `100%`,
  },
  bigLink: {
    '&:hover': {
      textDecoration: `none`,
    },
  },
  icon: {
    transition: `color 0.125s ease-in`,
    padding: `1rem`,
    color: `slategray`,
    fontSize: `2rem`,
    '&:hover': {
      color: `#17877e`,
    },
    [theme.breakpoints.down('sm')]: {
      padding: `0 1rem 0 0`,
    },
  },
  paper: {
    display: `flex`,
    flexDirection: `row`,
    alignItems: `center`,
    padding: `.8rem`,
    textAlign: `center`,
    border: `2px solid #ffffff`,
    boxShadow: `0 2px 2px rgba(0,0,0,.15)`,
    transition: `border-color 0.125s ease-in`,
    '&:hover': {
      border: `2px solid #17877e`,
      '& svg': {
        color: `#17877e`,
      },
    },
  },
  desktopLogo: {
    visibility: `hidden`,
    [theme.breakpoints.up('sm')]: {
      visibility: `visible`,
    },
  },
  buttonGrid: {
    display: `flex`,
    alignItems: `center`,
    justifyContent: `center`,
    flexDirection: `row`,
    [theme.breakpoints.down('sm')]: {
      flexDirection: `column`,
      paddingBottom: `2rem`,
    },
  },
  btnOption: {
    margin: `2rem 3rem 0 0`,
    padding: `1rem 3rem`,
    border: `1px solid ` + theme.palette.primary.main,
    color: `#fff`,
    background: theme.palette.primary.main,
    fontFamily: `Merriweather`,
    textTransform: `none`,
    fontWeight: `900`,
    borderRadius: `10px`,
    boxShadow: `rgba(20, 123, 201, .4) 0px 8px 25px 0px`,
    fontSize: `1.125rem`,
    [theme.breakpoints.down('sm')]: {
      margin: `2rem auto`,
      display: `flex`,
    },
    '&:hover': {
      // color: theme.palette.primary.dark,
      // background: `#fff`,
      boxShadow: `rgba(20, 123, 201, .4) 0px 8px 25px 0px`,
    },
  },
  modelHome: {
    maxWidth: `100%`,
    display: `block`,
    margin: `0 0 auto`,
    [theme.breakpoints.down('sm')]: {
      margin: `2rem auto`,
    },
  },
  businessName: {
    fontFamily: `"Merriweather Sans", sans-serif`,
    fontWeight: `700`,
  },
  points: {
    margin: `2px 0px 0px`,
    fontSize: `.8rem`,
  },
}));

const Landing = (props) => {
  const { t, setActiveStep, options, setfromLanding, isPartner, isEmbed, appLoading } = props;
  const classes = useStyles();
  const globalState = useContext(store);
  const { dispatch } = globalState;

  const vals = {
    a: 'pre_approve',
    b: 'purchase',
    c: 'switch',
  };

  const FinanceA = withRouter(({ history }) => (
    <Button
      onClick={() => {
        dispatch({ type: 'financeType', newVal: 'a' });
        dispatch({ type: 'inProgress', newVal: true });

        TagManager.dataLayer({
          dataLayer: {
            loanType: vals['a'],
          },
        });

        const route = props.match.url;
        const updatedRoute = route.replace('options', 'step-2');
        props.history.push(updatedRoute + props?.history?.location?.search);
        setActiveStep(2);
        setfromLanding(true);
      }}
      variant={'contained'}
      color="primary"
      className={classes.btnOption}
      id="options-finance-a"
    >
      {t('questions:financeType.option.1.bold')}
    </Button>
  ));

  const FinanceB = withRouter(({ history }) => (
    <Button
      onClick={() => {
        dispatch({ type: 'financeType', newVal: 'b' });
        dispatch({ type: 'inProgress', newVal: true });

        TagManager.dataLayer({
          dataLayer: {
            loanType: vals['b'],
          },
        });

        const route = props.match.url;
        const updatedRoute = route.replace('options', 'step-2');
        props.history.push(updatedRoute + props?.history?.location?.search);
        setActiveStep(2);
        setfromLanding(true);
      }}
      variant={'contained'}
      color="primary"
      className={classes.btnOption}
      id="options-finance-b"
    >
      {t('questions:financeType.option.2.bold')}
    </Button>
  ));

  const FinanceC = withRouter(({ history }) => (
    <Button
      onClick={() => {
        dispatch({ type: 'financeType', newVal: 'c' });
        dispatch({ type: 'inProgress', newVal: true });

        TagManager.dataLayer({
          dataLayer: {
            loanType: vals['c'],
          },
        });

        const route = props.match.url;
        const updatedRoute = route.replace('options', 'step-2');
        props.history.push(updatedRoute + props?.history?.location?.search);
        setActiveStep(2);
        setfromLanding(true);
      }}
      variant={'contained'}
      color="primary"
      className={classes.btnOption}
      id="options-finance-c"
    >
      {t('questions:financeType.option.3.bold')}
    </Button>
  ));

  const pointForm = [1, 2, 3];

  // GTM dataLayer push for timer trigger
  useEffect(() => {
    const tagManagerArgs = {
      dataLayer: {
        appName: 'Intro',
      },
    };
    TagManager.dataLayer(tagManagerArgs);
  }, []);

  return (
    <section className={isEmbed ? classes.embed : classes.hero}>
      <Container maxWidth="md">
        <Grid container justifyContent="center">
          <Grid item xs={12} md={10}>
            <Hidden smDown>
              <Fade left>
                <img src={greeting} width="100" height="79" alt="Hi!" loading="lazy" />
              </Fade>
            </Hidden>
            <Hidden mdUp>
              <Fade left>
                <img src={greeting} width="70" height="55.44" alt="Hi!" loading="lazy" />
              </Fade>
            </Hidden>
            <Fade delay={600}>
              <>
                <h1
                  className="topTitle"
                  dangerouslySetInnerHTML={{
                    __html: t('title', {
                      start: "<span class='highlight'>",
                      end: '</span>',
                      interpolation: { escapeValue: false },
                    }),
                  }}
                />
              </>
            </Fade>
            <Fade delay={700}>
              <p style={{ lineHeight: `1.6rem` }}>{t('sub')}</p>
            </Fade>
          </Grid>
        </Grid>
        {!options ? (
          <Grid container justifyContent="center">
            <Grid item xs={12} md={6}>
              <List component="ul">
                <Fade delay={700}>
                  {pointForm.map((item) => (
                    <ListItem key={item} alignItems="flex-start" disableGutters style={{ paddinTop: `0px` }}>
                      <ListItemIcon style={{ marginTop: '.2rem', minWidth: `auto`, flexShrink: `unset`, paddingRight: `.5rem` }}>
                        <CheckCircleOutlineIcon color="secondary" style={{ fontSize: '1.25rem' }} />
                      </ListItemIcon>
                      <p className={classes.points}>
                        <strong>{t('steps.' + item + '.bold')}</strong> {t('steps.' + item + '.copy')}
                      </p>
                    </ListItem>
                  ))}
                </Fade>
              </List>
              <Fade delay={800}>
                {appLoading ? (
                  <Loading />
                ) : // Opens app in a new window if the instance is in a incognito browser window.
                props?.isIncognito ? (
                  <Button
                    variant="contained"
                    color="primary"
                    className={classes.btnOption}
                    style={{ background: `#147AC8` }}
                    id="btnGetStarted"
                    href={`${config.hoa}${props.location.pathname
                      .toString()
                      .replace(/(?:\/+(\?))/, '$1')
                      .replace(/\/+$/, '')}/step-1${props?.history?.location?.search}`}
                    target="_blank"
                    disabled={appLoading}
                  >
                    {t('items.0.title1')}
                  </Button>
                ) : (
                  <Button
                    variant="contained"
                    color="primary"
                    className={classes.btnOption}
                    style={{ background: `#147AC8` }}
                    id="btnGetStarted"
                    onClick={() => {
                      const route = props.match.url;
                      const updatedRoute = route === '/' ? route + `step-1` : route + `/step-1`;
                      props.history.push(updatedRoute + props?.history?.location?.search);
                      setActiveStep(1);
                      setfromLanding(true);
                    }}
                    disabled={appLoading}
                  >
                    {t('items.0.title1')}
                  </Button>
                )}
              </Fade>
            </Grid>
            <Hidden smDown>
              <Grid item xs={12} md={4}>
                <Fade delay={800}>
                  <img
                    src={isPartner ? getstartedpartner : getstarted}
                    alt="Get Started with Homewise"
                    width="280"
                    className={classes.modelHome}
                    loading="lazy"
                  />
                </Fade>
              </Grid>
            </Hidden>
          </Grid>
        ) : (
          <Grid container justifyContent="center">
            <Grid item xs={12} md={5}>
              <List component="ul">
                <Fade delay={700}>
                  {pointForm.map((item) => (
                    <ListItem key={item} alignItems="flex-start" disableGutters style={{ paddinTop: `0px` }}>
                      <ListItemIcon style={{ marginTop: '.2rem', minWidth: `auto`, flexShrink: `unset`, paddingRight: `.5rem` }}>
                        <CheckCircleOutlineIcon color="secondary" />
                      </ListItemIcon>
                      <p className={classes.points}>
                        <strong>{t('steps.' + item + '.bold')}</strong> {t('steps.' + item + '.copy')}
                      </p>
                    </ListItem>
                  ))}
                </Fade>
              </List>
            </Grid>
            <Hidden smDown>
              <Grid item xs={12} md={4}>
                <Fade delay={800}>
                  <img
                    src={isPartner ? getstartedpartner : getstarted}
                    alt="Get Started with Homewise"
                    width="280"
                    className={classes.modelHome}
                    loading="lazy"
                  />
                </Fade>
              </Grid>
            </Hidden>
            <Grid item xs={12}>
              {appLoading ? (
                <Loading />
              ) : (
                <div className={classes.buttonGrid}>
                  <Fade up delay={900}>
                    <FinanceA />
                  </Fade>
                  <Fade up delay={1000}>
                    <FinanceB />
                  </Fade>
                  <Fade up delay={1100}>
                    <FinanceC />
                  </Fade>
                </div>
              )}
            </Grid>
            <Grid item xs={12}>
              <Fade delay={1300}>
                <p className="legal" style={{ marginTop: `1rem` }}>
                  {t('legal')}
                </p>
              </Fade>
            </Grid>
          </Grid>
        )}
      </Container>
    </section>
  );
};

export default withRouter(withTranslation('getstarted')(Landing));
