import React from "react";
import ReactDOM from "react-dom";
import { createBrowserHistory } from 'history';
import "./styles/index.css";
import Router from "./components/Router";
import "./i18n";
import pym from "pym.js";

import * as Sentry from "@sentry/react";
import { Integrations } from '@sentry/tracing';
import { Offline as OfflineIntegration } from "@sentry/integrations";
import SentryRRWeb from "@sentry/rrweb";
import { version } from '../package.json';

const history = createBrowserHistory();

var pymChild = new pym.Child({ polling: 500 }); //eslint-disable-line

Sentry.init({
  dsn: process.env.REACT_APP_SENTRY_DSN,
  environment: process.env.REACT_APP_STAGE,
  release: version,
  integrations: [
    new Integrations.BrowserTracing(),
    new OfflineIntegration(
    {
      maxStoredEvents: 100
    }),
    new Integrations.BrowserTracing({
      routingInstrumentation: Sentry.reactRouterV5Instrumentation(history),
    }),
    new SentryRRWeb()
  ],
  normalizeDepth: 5,
  tracesSampleRate: 0.5,
  ignoreErrors: [
    'paidAi',
    'widgetapp'
  ],
  denyUrls: [
    /graph\.facebook\.com/i,
    /connect\.facebook\.net\/en_US\/all\.js/i,
    /www\.google-analytics\.com/i,
    /www\.google-analytics\.com\/all\.js/i,
    /cdn\.userway\.org/i,
    /api\.userway\.org\/api/i,
    // Chrome extensions
    /extensions\//i,
    /^chrome:\/\//i,
  ]
});

ReactDOM.render(
  <Sentry.ErrorBoundary fallback={"An error has occurred"}>
    <Router history={history} pymChild={pymChild}/>
  </Sentry.ErrorBoundary>,
  document.getElementById("root")
);
