export const partner = [
  {
    path: '/p/:alias1',
    isEmbed: false,
    options: false,
  },
  {
    path: '/p/:alias1/step-:step',
    isEmbed: false,
    options: false,
  },
  {
    path: '/p/:alias1/options',
    isEmbed: false,
    options: true,
  },
  {
    path: '/p/:alias1/embed',
    isEmbed: true,
    options: false,
  },
  {
    path: '/p/:alias1/embed/step-:step',
    isEmbed: true,
    options: false,
  },
  {
    path: '/p/:alias1/embed/options',
    isEmbed: true,
    options: true,
  },
  {
    path: '/p/:alias1/:alias2',
    isEmbed: false,
    options: false,
  },
  {
    path: '/p/:alias1/:alias2/step-:step',
    isEmbed: false,
    options: false,
  },
  {
    path: '/p/:alias1/:alias2/options',
    isEmbed: false,
    options: true,
  },
  {
    path: '/p/:alias1/:alias2/embed',
    isEmbed: true,
    options: false,
  },
  {
    path: '/p/:alias1/:alias2/embed/step-:step',
    isEmbed: true,
    options: false,
  },
  {
    path: '/p/:alias1/:alias2/embed/options',
    isEmbed: true,
    options: true,
  },
  {
    path: '/partner/:alias1',
    isEmbed: false,
    options: false,
  },
  {
    path: '/partner/:alias1/step-:step',
    isEmbed: false,
    options: false,
  },
  {
    path: '/partner/:alias1/options',
    isEmbed: false,
    options: true,
  },
  {
    path: '/partner/:alias1/embed',
    isEmbed: true,
    options: false,
  },
  {
    path: '/partner/:alias1/embed/step-:step',
    isEmbed: true,
    options: false,
  },
  {
    path: '/partner/:alias1/embed/options',
    isEmbed: true,
    options: true,
  },
  {
    path: '/partner/:alias1/:alias2',
    isEmbed: false,
    options: false,
  },
  {
    path: '/partner/:alias1/:alias2/step-:step',
    isEmbed: false,
    options: false,
  },
  {
    path: '/partner/:alias1/:alias2/options',
    isEmbed: false,
    options: true,
  },
  {
    path: '/partner/:alias1/:alias2/embed',
    isEmbed: true,
    options: false,
  },
  {
    path: '/partner/:alias1/:alias2/embed/step-:step',
    isEmbed: true,
    options: false,
  },
  {
    path: '/partner/:alias1/:alias2/embed/options',
    isEmbed: true,
    options: true,
  },
];
