import React, { useContext, useEffect } from "react";
import { useInterval } from "../utils/hooks/useInterval";
import { withStyles } from "@material-ui/core/styles";
import { withTranslation } from "react-i18next";

import Footer from "./Footer";
import Controller from "../pages/Landing/Controller";
import SEO from "../components/Seo";

import UserContext from "../store/app/Context";
import useCustomBg from "utils/hooks/useCustomBg";

const styles = (theme, isEmbed) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    minHeight: "100vh",
  },
  embedRoot: {
    flexDirection: "column",
  },
  appBar: {
    position: "relative",
  },
  loading: {
    display: `flex`,
    flexDirection: `column`,
    width: `100px`,
    alignItems: `center`,
    justifyItems: `center`,
    margin: `auto`,
  },
  home: {
    width: `100%`,
    marginBottom: `-2rem`,
  },
});

const App = (props) => {
  const { t } = props;
  const {
    getSchema,
    getSession,
    getRefreshedSession,
    auth,
    appLoading,
    isError,
    errMessage,
  } = useContext(UserContext);

  // Refresh session every 15mins
  useInterval(() => {
    getRefreshedSession("app");
  }, 1200000);

  useEffect(() => {
    if ("serviceWorker" in navigator) {
      // navigator.serviceWorker.register("/sw.js");
    }

    const activeSession = localStorage.getItem("activeSession") === "true";
    const authExist = Object.keys(auth).length > 0;

    if (authExist) {
      if (auth.jwtToken.length > 0) getSchema();
    } else if (activeSession) getSession("appMount");
    else getSchema();
  }, [getSchema, getSession, auth]);

  const {
    classes,
    isEmbed = false,
    group,
    options,
    match: { params },
  } = props;
  const { step, alias1, alias2 } = params;

  /** Send pym height with every update */
  useEffect(() => {
    try {
      const { pymChild } = props;

      // Updates the height of the iframe if the page is resized
      if (pymChild) {
        pymChild.sendHeight();
        // pymChild.scrollParentTo('homewise_mortgage_app_pym');
      }
    } catch (e) {
      console.log("pym error", e);
    }
  });

  const customBg = useCustomBg(isEmbed);

  return (
    <div
      className={isEmbed ? classes.embedRoot : classes.root}
      style={{ backgroundColor: customBg }}
    >
      <SEO
        title={t("meta.landing.title")}
        metaImage={"social_v2.png"}
        description={t("meta.landing.description")}
        pathname={"/"}
      />
      {isError && <p style={{ textAlign: `center` }}>{errMessage}</p>}

      <Controller
        options={options}
        routeStep={step}
        alias1={alias1}
        alias2={alias2}
        group={group}
        isEmbed={isEmbed}
        appLoading={appLoading}
      />

      {step === undefined || step === null || isNaN(step) ? (
        !isEmbed ? (
          <Footer />
        ) : null
      ) : null}
    </div>
  );
};

export default withStyles(styles)(withTranslation("common")(App));
