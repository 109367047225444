import React from 'react';
import { Translation } from 'react-i18next';
import { withStyles } from '@material-ui/core/styles';

import MainLayout from '../components/layouts/Main';

import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Container from '@material-ui/core/Container';
import Button from '@material-ui/core/Button';

import pups from '../imgs/group-portrait-adorable-puppies.jpg';

const styles = (theme) => ({
  margin: {
    margin: `10px`,
  },
  hero: {
    padding: `10px 0px `,
    // height: `calc(100vh - 102px)`,

    // [theme.breakpoints.up("md")]: {
    //   padding: `100px 0px 0px`,
    //   height: `calc(100vh - 192px)`,
    // },
    // display: `flex`,
    // overflow: `hidden`,
  },
  paper: {
    textAlign: 'center',
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
    padding: theme.spacing(2),
    [theme.breakpoints.up(600 + theme.spacing(3) * 2)]: {
      marginTop: theme.spacing(6),
      marginBottom: theme.spacing(3),
      padding: theme.spacing(3),
    },
  },
});

class NotFound extends React.Component {
  render() {
    const { classes } = this.props;

    return (
      <MainLayout showFlag={true}>
        <section className={classes.hero}>
          <Container maxWidth="md">
            <Grid container justifyContent="center">
              <Grid item xs={12} sm={8}>
                <Paper
                  className={classes.paper}
                  style={{
                    padding: `2em 1em 2.5em`,
                    textAlign: `left`,
                    boxShadow: `0 2px 2px rgba(0,0,0,.15), inset 0 -2px 0 #ccefc4`,
                  }}
                >
                  <Grid item xs={12} style={{ padding: `0 24px` }}>
                    <Translation>
                      {(t) => (
                        <h2 style={{ marginTop: `0px` }}>
                          {t('common:notfound.title')}
                        </h2>
                      )}
                    </Translation>
                    <Translation>
                      {(t) => <p>{t('common:notfound.tagline')}</p>}
                    </Translation>
                    <Grid container style={{ paddingTop: `2rem` }}>
                      <Button
                        variant="contained"
                        color="primary"
                        className={classes.button}
                        href="../"
                      >
                        <Translation>
                          {(t) => <span>{t('common:notfound.cta')}</span>}
                        </Translation>
                      </Button>
                    </Grid>
                  </Grid>
                </Paper>
              </Grid>
            </Grid>
          </Container>
        </section>
        <img
          src={pups}
          style={{
            position: 'absolute',
            left: 0,
            top: 0,
            width: '100%',
            height: '100%',
            zIndex: '-1',
            objectFit: 'cover',
            objectPosition: 'center center',
          }}
          alt=""
        />
      </MainLayout>
    );
  }
}

export default withStyles(styles)(NotFound);
